import React from "react";
import { graphql } from "gatsby"
import { Link } from "gatsby";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import ReactGA from "react-ga";
import BlogHero from "../components/blog-hero";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Gist from "../components/gist";
import DonationFooter from "../components/donation-footer";
import AdBanner from "../components/ad-banner";
import GumroadButton from "../components/gumroad-button";

class Template extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    ReactGA.initialize("UA-115464417-1");
    if (!(window.location.hostname === "localhost")) {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
    this.asyncScript('https://cdn.rawgit.com/google/code-prettify/master/loader/run_prettify.js');
  }

  asyncScript = (scriptPath) => {

    const script = document.createElement('script');
    script.src = scriptPath;
    script.async = false;
    document.body.appendChild(script);

  }

  render() {

    const { markdownRemark } = this.props.data;
    const { frontmatter, html } = markdownRemark;

    return (
      <Layout>
        <SEO
          title={frontmatter.title}
          keywords={[
          ]}
        />

        {/* <div style={{marginTop: 30}}></div> */}
        {/* <AdBanner></AdBanner> */}
        {/* <div style={{marginTop: 30}}></div> */}

        {/* <Container>
					<div style={{ marginTop: "2rem" }} />
					<Row>
						<Col>
							<Link to="/blog"><Button variant="light">⬅ Back</Button></Link>
						</Col>
					</Row>
				</Container> */}

        <BlogHero
          header={frontmatter.header}
          subHeader={frontmatter.subHeader}
          maxWidth={650}
        />

        {(frontmatter.youtubeVideo.length > 0)
          ?
          <Container>
            <Row style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: '4rem'
            }}>
              <Col style={{ maxWidth: 650 }}>
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe className="embed-responsive-item" src={`https://www.youtube.com/embed/${frontmatter.youtubeVideo}`} allowfullscreen></iframe>
                </div>
              </Col>
            </Row>
          </Container>
          :
          null
        }

        <Container style={{ maxWidth: 650 }}>
          <div
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </Container>

        <div style={{ marginTop: 100 }}></div>
        <DonationFooter />

      </Layout>

    )
  }

}

export default Template;

// export default function Template({ data }) {

//   const { markdownRemark } = data
//   const { frontmatter, html } = markdownRemark

//   return (
//     <div className="blog-post-container">
//       <div className="blog-post">
//         <h1>{frontmatter.title}</h1>
//         <h2>{frontmatter.date}</h2>
//         <div
//           className="blog-post-content"
//           dangerouslySetInnerHTML={{ __html: html }}
//         />
//       </div>
//     </div>
//   )

// }

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        description
        keywords
        header
        subHeader
        youtubeVideo
      }
    }
  }
`